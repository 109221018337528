import * as React from "react";
import { useState } from "react";
import styles from "./styles.module.css";
import NKLogo from "../../assets/images/nklogo.png";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { ORDER, PRODUCT } from "../../utils/constant";
import { fetchAll, fetchSales } from "../../api";

const Summary = () => {
  let navigate = useNavigate();
  const [skus, setSkus] = React.useState([])
  const [orderList, setOrderList] = useState([]);
  const [partner, setPartner] = useState([]);
  const [finalItems, setFinalItems] = useState([])

  React.useEffect(() => {
    getAllProducts()
    getSalesReports()
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
      setPartner(userData)

    }
  }, []);

  React.useEffect(() => {
    if(skus.length && orderList.length){
      getItemsSummary()
    }
    
  }, [skus, orderList])

  const getAllProducts = async () => {
    try {
      const response = await fetchAll(PRODUCT)
      if (response.data) {
        setSkus(response.data.data)

      }
    } catch (error) { }
  }

  const getSalesReports = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData) {
        const response = await fetchSales(ORDER, 'kid', userData.kid, 'date', moment(new Date()).format("YYYY-MM-DD"), moment(new Date()).add(1, 'days').format("YYYY-MM-DD"));
        if (response.data) {
          let data = response.data.data.filter(e => e.status === 'Completed').filter(f => f.items.length)
          setOrderList(data)

        }
      }
    } catch (error) {
      console.log(error)
    }
  };

  const getOrderDetails = (orders, skus) => {
    let data = orders.map(e => {
      return {
        ...e,
        items: e.items.map(f => {
          return {
            ...f,
            itemName: skus && skus.length > 0 ? skus.filter(r => r._id === f.skuid)[0]?.skuName : '',
            logo: skus && skus.length > 0 ? skus.filter(r => r._id === f.skuid)[0]?.image : ''
          }
        })
      }
    })

    let skewersList = []
    let briyanisList = []
    data.forEach((ele) => {
      if (ele.items.length) {
        skewersList.push(ele.items.filter((f) => f.itemName.includes('Kebab'))[0])
        briyanisList.push(ele.items.filter((f) => f.itemName.includes('Biryani'))[0])
      }
    });

    briyanisList = briyanisList.filter(e => !!e)
    skewersList = skewersList.filter(e => !!e)
    let skewersCount = 0
    let briyanisCount = 0
    skewersCount += parseInt(skewersList.reduce((a, v) => v.quantity + a, 0));
    briyanisCount += parseInt(briyanisList.reduce((a, v) => v.quantity + a, 0));
    let summaryDetails = [
      {
        image: briyanisList[0]?.logo,
        skuName: briyanisList[0]?.itemName,
        price: briyanisList[0]?.price,
        quantity: briyanisCount
      },
      {
        image: skewersList[0]?.logo,
        skuName: skewersList[0]?.itemName,
        price: skewersList[0]?.price,
        quantity: skewersCount
      },
    ]

    return summaryDetails;

  }

  const getTotalAmount = (orders) => {
    let totalAmt = 0
    orders.forEach((e) => {
      totalAmt += parseFloat(e.totalamount)
    });

    return totalAmt
  }

  const navigateTo = () => {
    localStorage.removeItem('userData');
    navigate(`/`)
  }

  const Header = (
    <div className={styles.header}>
      <img style={{ marginLeft: 20 }} alt="NKLogo" src={NKLogo} />
      <div className={styles.headerHeading}>
        <div className={styles.headingKey}>Outlet ID</div>
        <div className={styles.headingValue}>{partner && partner.kid ? partner.kid : ''}</div>
      </div>
      <div>hi</div>
    </div>
  );

  const getItemsSummary = () => {

    let data = orderList.filter((f) => f.status === "Completed")
    // console.log(data, "data")
    let finalTotalData = []
    data.forEach(e => {
      e.items.forEach(f => {
        // console.log(finalTotalData, "finalTotalData")
        if (finalTotalData.length && finalTotalData.filter(m => m.id == f.skuid).length) {
          let index=finalTotalData.findIndex(m => m.id == f.skuid)
          finalTotalData[index].quantity=finalTotalData[index].quantity+f.quantity
         
        } else {
   
          let skuData=skus.filter(s => s._id === f.skuid)[0]
          let obj = {
            id: f.skuid,
            quantity: f.quantity,
            name: skuData?.skuName,
            image: skuData?.image
          }
          if(obj){
            finalTotalData.push(obj)
          }
          
        }
      })
    })
    setFinalItems(finalTotalData)


  }

  const summaryCard = (
    <div className={styles.summaryContainer}>
      <div className={styles.heading}>Summary of the Day</div>
      <div className={styles.totalAmount}>
        <div className={styles.total}>₹{orderList && orderList.length > 0 && orderList[0]?.items.length > 0 ? getTotalAmount(orderList) : 0}</div>
        <div className={styles.totalText}>Total Amount</div>
      </div>
      <div className={styles.menuContainer}>
        {finalItems.map(item => (
          <div key={item.id}>
            <div className={styles.cardContainer}>
              <div
                    className={styles.cardImage}
                    style={{ backgroundImage: `url(${item.image})` }}
                  ></div>
              <div className={styles.cardDetails}>
                <div className={styles.itemName}>{item.name}🔥</div>
                <div className={styles.itemPrice}>{item.price} X {item.quantity}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.divider}></div>
      <div className={styles.totalOrders}>
        <div className={styles.orderHeading}>Total Orders</div>
        <div className={styles.orderValue}>{orderList && orderList.length}</div>
      </div>
    </div>
  );
  return (
    <div
      style={{ backgroundColor: "#0F0D0E", height: "100%", minHeight: "100vh" }}
    >
      {Header}
      {summaryCard}
      <div onClick={() => navigateTo()} className={styles.loginAgain}>Login Again</div>
      <br/> <br/>
    </div>
  );
};
export default Summary;
