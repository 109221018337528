import React from "react";
import styles from "./styles.module.css";
import logo from '../../assets/images/logo.png'
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from 'react-toastify';
import { login, fetchAll } from "../../api";
import { KIOSK, DISPATCH } from "../../utils/constant"

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const [kiosks, setKiosks] = React.useState([])
  const [dispatch, setDispatch] = React.useState([]);
  let navigate = useNavigate();
  const loginValidationSchema = yup.object().shape({
    username: yup.string().required("Mobile number is required"),
    password: yup.string().required("Password is required"),
  });

  React.useEffect(() => {
    getDispatchData()
    if (localStorage.hasOwnProperty('userData')) {
      localStorage.removeItem('userData');
    }
  }, [])

  // const getAllKiosks = async () => {
  //   try {
  //     const response = await fetchAll(KIOSK)
  //     if (response.data) {
  //       setKiosks(response.data.data)
  //     }
  //   } catch (error) {
  //     toast.error(error.data.data.message);
  //     console.log(error)
  //   }
  // }

  const getDispatchData = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData) {
        const response = await fetchAll(DISPATCH, 'kid', userData.kid);
        if (response.data) {
          setDispatch(response.data.data);
        }
      }
    } catch (error) {
      console.log(error)
    }
  };

  // const checkDispatchQty = (dispatch) => {
  //   if (dispatch[0].items.length > 0) {
  //     return true;
  //   } else {
  //     toast.error('No Dispatch Data');
  //     return false;
  //   }
  // }

  const partnerAssociation = (kioskData, partnerDetails) => {
    // let kisokDetails = kioskData.find(e => e.prnid === partnerDetails.prnid)
    let kisokDetails = kioskData.find(e => e.opid === partnerDetails.opid)
    if (kisokDetails) {
      let partnerData = {
        ...partnerDetails,
        kid: kisokDetails.kid,
      }
      let userData = JSON.stringify(partnerData);
      localStorage.setItem('userData', userData);
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="container-fluid">
      <Formik
        validationSchema={loginValidationSchema}
        initialValues={{ username: "", password: "" }}
        onSubmit={values => {
          setLoading(true);
          // console.log('called');
          try {
            login(values.username, values.password).then(
              async res => {
                if (res.status === 200) {
                  if (res.data.data === null) {
                    toast.error(res.data.message);
                    setLoading(false);
                  } else {
                    if(res.data.data.statusCode === 401) {
                      toast.error(res.data.data.message);
                      setLoading(false);
                      return;
                    }
                    if (res.data.data.deleted === false) {
                      const response = await fetchAll(KIOSK)
                      if (response.data && response.data.data) {
                        if (partnerAssociation(response.data.data, res.data.data)) {
                          toast.success('Success');
                          navigate('/home')
                        } else {
                          toast.error('No kiosk found for partner');
                          navigate('/')
                          setLoading(false);
                        }
                        setLoading(false);
                      } else {
                        toast.error('No kiosk found');
                        navigate('/')
                        setLoading(false);
                      }
                    } else {
                      toast.error('In Active User');
                      navigate('/')
                      setLoading(false);
                    }
                  }

                }
              },
              err => {
                console.log(err, 'error');
                if (err.response.data.statusCode === 400) {
                  toast.error('User Not Found');
                } else {
                  toast.error('Failure');
                }
                setLoading(false);
              },
            );
          } catch (error) {
            console.log(error, 'error');
            // setLoading(false);
          }
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <div className="row">
            <div className={`${styles.loginContainer} col-sm-12`}>
              <div className="background"></div>
              <div className={styles.loginContent}>
                <div className={styles.center}>
                  <img src={logo} alt="logo" />
                </div>

                <div className={styles.title}>Login</div>
                <div className={styles.caption}>Sign In to Continue</div>

                <div className={styles.label}>Mobile Number</div>
                <input
                  className={styles.input}
                  value={values.username}
                  placeholder="Enter your mobile number"
                  onBlur={handleBlur("username")}
                  onChange={handleChange("username")}
                />
                <p className={styles.error}>
                  {touched.username && errors.username}
                </p>
                <div className={styles.label}>Password</div>
                <input
                  type={values.showPass ? "text" : "password"}
                  value={values.password}
                  className={styles.input}
                  placeholder="Enter your password"
                  onBlur={handleBlur("password")}
                  onChange={handleChange("password")}
                />
                <p className={styles.error}>
                  {touched.password && errors.password}
                </p>
                <button onClick={handleSubmit} disabled={loading} className={styles.button}>Login</button>
                {/* <div className={styles.forgetPwd}>Forgot Password?</div> */}
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Login;
