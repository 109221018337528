import axios from "axios";
// 
axios.defaults.baseURL = "https://delectable.appswise.tech/api/v1";
// axios.defaults.baseURL = "http://localhost:3001/api/v1";

export const login = (username, password) => {
    console.log(username,password)
    const config = {
        headers: {
            username: username,
            password: password,
            'Content-Type': 'application/json',
        }
    };
    return axios.get("/operator/login", config);
};

export const fetchAll = (category, attribute, value) => {
    let url = `${category}`
    if (attribute && value) {
        url = url + `?attr=${attribute}&val=${value}`
    }

    return axios.get(url);
};

export const fetchSales = (category, attribute, value, dateAttr, fromDate, toDate) => {
    return axios.get(`${category}?attr=${attribute}&val=${value}&attr=${dateAttr}&startDate=${fromDate}&endDate=${toDate}`);
};

export const update = (category, id, body) => {
    return axios.put(`${category}/${id}`, body);
};

export const deleteOne = (category, id) => {
    return axios.delete(`${category}/${id}`);
};