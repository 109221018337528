import React from "react";
import Login from "./components/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Summary from "./components/summary";

function App() {
  React.useEffect(()=>{
    window.addEventListener('popstate', (e) => {
       window.history.go(1);
     });

     if ('caches' in window) {
      caches.keys().then((names) => {
        console.log(names, 'caches')
        names.forEach(name => {
          caches.delete(name);
        })
      })
    } else {
      console.log('cache not found')
    }
    
   },[])

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/summary" element={<Summary />} />
      </Routes>
    </Router>
  );
}

export default App;
