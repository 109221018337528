import React from "react";
import "./spinner.css";
import loader from '../../assets/loader.gif'

export default function LoadingSpinner() {
    return (
        <div className="full_screen">
            <img src={loader} className="gif" alt="spinner logo"/>
        </div>
    );
}