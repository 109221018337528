function Dialog({ message, onDialog, nameProduct }) {
    return (
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          backgroundColor: "rgba(0,0,0,0.5)"
        }}
        onClick={() => onDialog(false)}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            zIndex: '9999',
            textAlign: 'center',
          }}
        >
          <h3 style={{ color: "#000000a6", fontSize: "16px" }}>{message}</h3>
          <h2 style={{ fontSize: "18px",margin: "10px" }}>Token No. {nameProduct}</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={() => onDialog(true)}
              style={{
                background: "red",
                color: "white",
                borderRadius: "5px",
                cursor: "pointer",
                margin: '0 10px',
                border: 0,
                padding: '8px 16px',
              }}
            >
              YES
            </button>
            <button
              onClick={() => onDialog(false)}
              style={{
                background: "green",
                color: "white",
                color: "white",
                borderRadius: "5px",
                cursor: "pointer",
                margin: '0 10px',
                border: 0,
                padding: '8px 16px',
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    );
  }
  export default Dialog;
  