import * as React from "react";
import styles from "./styles.module.css";
import NKLogo from "../../assets/images/nklogo.png";
import Signout from "../../assets/images/signout.png";
import Chef from "../../assets/images/chef.png";
import TakeAway from "../../assets/images/parcel.png";
import Havingit from "../../assets/images/dining.png";
import Cash from "../../assets/images/cash.png";
import Online from "../../assets/images/online.png";
import Done from "../../assets/images/done.png";
import { useNavigate } from "react-router-dom";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import { useState, useRef } from "react";
import { ORDER, PRODUCT, KIOSK, OPERATOR } from "../../utils/constant";
import { fetchAll, fetchSales, update, deleteOne } from "../../api";
import moment from "moment";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import Dialog from "../Dialog"
import LoadingSpinner from "../../helper/spinner/spinner";


const Home = () => {
  let navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [skus, setSkus] = React.useState([]);
  const [orderList, setOrderList] = useState([]);
  const [partner, setPartner] = useState([]);
  const [dispatch, setDispatch] = useState([]);
  const [stock, setStock] = useState({})
  const [socket, setSocket] = useState(null)
  const idProductRef = useRef();
  const [loading, setIsLoading] = useState(false);

  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
    //Update
    nameProduct: ""
  });

  React.useEffect(() => {
    getAllProducts();
    getSalesReports();
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setPartner(userData);
    }
  }, []);

  React.useEffect(() => {
    const newSocket = io("https://delectable.appswise.tech");
    newSocket.on("connect", () => {
      console.log(newSocket.id); // x8WIv7-mJelg7on_ALbx
      if (newSocket.id) {
        setSocket(newSocket);
        newSocket.on("fetchUpdatedData", (res) => {
          console.log("calledFetchdata");
          getSalesReports();
        });
      }
    });
    newSocket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
  }, []);

  // const getKiosk = async (orderData) => {
  //   console.log(orderData)
  //   try {
  //     const userData = JSON.parse(localStorage.getItem('userData'));
  //     if (userData) {
  //       const response = await fetchAll(KIOSK, 'kid', userData.kid)
  //       if (response.data) {
  //         let orders = [orderData].map(e => e.items).flat(1)
  //         let kisokData = response.data.data;
  //         kisokData = kisokData.map(e => e.items).flat(1)
  //         const updatedKioskData = kisokData.map(e => {
  //           let ordersItems = orders.filter(f => {
  //             if (f.skuid === e.skuid) {
  //               return f
  //             }
  //           })
  //           let quantity = e.quantity - ordersItems.reduce((a, r) => a + r.quantity, 0)
  //           return {
  //             ...e,
  //             quantity: quantity
  //           }
  //         })
  //         try {
  //           const kioskResponse = await update(KIOSK, response.data.data[0]._id, { items: updatedKioskData });
  //           if (kioskResponse) {
  //             // toast.success(kioskResponse.data.message);
  //           }
  //         } catch (error) {
  //           console.log(error)
  //         }
  //       }
  //     }
  //   } catch (error) { }
  // }

  const getAllProducts = async () => {
    try {
      const response = await fetchAll(PRODUCT);
      if (response.data) {
        setSkus(response.data.data);
      }
    } catch (error) { }
  };

  const getKioskData = async (salesData, orderDetails) => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData) {
        const response = await fetchAll(KIOSK, "kid", userData.kid);
        if (response.data) {
          let data = response.data.data;
          let finalData = {};
          salesData.forEach((e) => {
            e.forEach((f) => {
              if (Object.keys(finalData).includes(f._id)) {
                finalData[f._id] = finalData[f._id] + f.quantity;
              } else {
                finalData[f._id] = f.quantity;
              }
            });
          });
          // console.log(salesData, data, "dat")
          let kioskData = response.data.data.map((e) => e.items);
          kioskData = kioskData.flat(1);
          kioskData.forEach((e) => {
            finalData[e.skuid] = finalData[e.skuid] ? e.quantity : e.quantity;
          });
          setStock(finalData);
          if (
            orderDetails.filter(
              (f) =>
                f.status === "Payment Pending" ||
                f.status === "Payment Completed"
            ).length == 0 &&
            Object.values(finalData).filter((e) => e > 0).length == 0
          ) {
            navigate("/summary");
          }
          // console.log(finalData, "finalData")
          setDispatch(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSalesReports = async () => {
    try {
      setIsLoading(true)
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData) {
        const response = await fetchSales(
          ORDER,
          "kid",
          userData.kid,
          "date",
          moment(new Date()).format("YYYY-MM-DD"),
          moment(new Date()).add(1, "days").format("YYYY-MM-DD")
        );
        if (response.data && response.data.data.length) {
          let data = response.data.data.filter(
            (e) => !e.deleted && e.items.length
          );
          setOrderList(data);
          getKioskData(
            data.map((e) => e.items),
            response.data.data
          );
        }
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false)
      // toast.error(error.response.data.message);
    }
  };

  // const getSkuTotal = (orderDetails, skuDetails, type) => {
  //   var ordersData = orderDetails.map(e => {
  //     return {
  //       ...e,
  //       items: e.items.map(f => {
  //         return {
  //           ...f,
  //           itemName: skuDetails && skuDetails.length > 0 ? skuDetails.filter(r => r._id === f.skuid)[0]?.skuName : ''
  //         }
  //       })
  //     }
  //   })

  //   let skewersList = []
  //   let briyanisList = []
  //   ordersData.forEach((ele) => {
  //     if (ele.items.length) {
  //       skewersList.push(ele.items.filter((f) => f.itemName.includes('Kebab'))[0]?.quantity)
  //       briyanisList.push(ele.items.filter((f) => f.itemName.includes('Biryani'))[0]?.quantity)
  //     }
  //   });

  //   briyanisList = briyanisList.filter(e => !!e)
  //   skewersList = skewersList.filter(e => !!e)
  //   let skewersCount = 0
  //   let briyanisCount = 0
  //   skewersCount += parseInt(skewersList.reduce((a, v) => v + a, 0));
  //   briyanisCount += parseInt(briyanisList.reduce((a, v) => v + a, 0));
  //   return type === 'skewers' ? skewersCount : briyanisCount
  // }

  const Header = (
    <div className={styles.header}>
      <img style={{ marginLeft: 20 }} alt="NKLogo" src={NKLogo} />
      <div className={styles.headerHeading}>
        <div className={styles.headingKey}>Outlet ID</div>
        <div className={styles.headingValue}>
          {partner && partner.kid ? partner.kid : ""}
        </div>
      </div>
      <img
        onClick={() => goToSummary()}
        style={{ marginRight: 30 }}
        alt="Signout"
        src={Signout}
      />
    </div>
  );

  const goToSummary = () => {
    if (
      orderList.filter(
        (f) =>
          f.status === "Payment Pending" || f.status === "Payment Completed"
      ).length
    ) {
      toast.warning("Kindly close all the pending items.");
    } else {
      const userData = JSON.parse(localStorage.getItem("userData"));
      update(OPERATOR, userData._id, { loggedIn: false });
      navigate("/summary");
    }
  };
  const getItems = (items) => {
    let fitems = []
    items.forEach(ele => {
      if (ele.isCombo) {
        ele.comboItems.map(e => {
          return {
            quantity: e.quantity * ele.quantity,
            _id: e.skuid,
            skuid: e.skuid
          }
        }).forEach(val =>{
          fitems.push(val)
        })
      } else {
        fitems.push(ele)
      }
    });
    return fitems
  }
  const getItemsSummary = () => {
    let data = orderList.filter(
      (f) => f.status === "Payment Pending" || f.status === "Payment Completed"
    );
    let finalcomboTotalData = [];
    let uncomboData = [];
    uncomboData = data.map(e => {
      return {
        ...e,
        items: getItems(e.items)
      }
    })
    uncomboData.forEach((e) => {
      e.items.forEach((f) => {
        if (
          finalcomboTotalData.length &&
          finalcomboTotalData.filter((m) => m.id == f.skuid).length
        ) {
          let index = finalcomboTotalData.findIndex((m) => m.id == f.skuid);
          finalcomboTotalData[index].quantity =
            finalcomboTotalData[index].quantity + f.quantity;
        } else {
          let skuData = skus.filter((s) => s._id === f.skuid)[0];
          let obj = {
            id: f.skuid,
            quantity: f.quantity,
            name: skuData?.skuName,
            image: skuData?.image,
            icon: skuData?.icon,
          };
          if (obj) {
            finalcomboTotalData.push(obj);
          }
        }
      });
    });
    // console.log(data)
    // console.log(finalcomboTotalData)
    let finalTotalData = [];
    data.forEach((e) => {
      e.items.forEach((f) => {
        if (
          finalTotalData.length &&
          finalTotalData.filter((m) => m.id == f.skuid).length
        ) {
          let index = finalTotalData.findIndex((m) => m.id == f.skuid);
          finalTotalData[index].quantity =
            finalTotalData[index].quantity + f.quantity;
        } else {
          let skuData = skus.filter((s) => s._id === f.skuid)[0];
          let obj = {
            id: f.skuid,
            quantity: f.quantity,
            name: skuData?.skuName,
            image: skuData?.image,
            icon: skuData?.icon,
          };
          if (obj) {
            finalTotalData.push(obj);
          }
        }
      });
    });
    return finalcomboTotalData.map((f) => (
      <div key={f.id} className={styles.summaryList}>
        <div style={{ display: "flex" }}>
          <img style={{ width: 20 }} src={f.icon} />
          <div className={styles.summaryKey}>No. of {f.name}</div>
        </div>
        <div className={styles.summaryValue}>{f.quantity}</div>
      </div>
    ));
  };

  const Summary = (
    <div className={styles.summaryBox}>
      <div className={styles.summaryList}>
        <div style={{ display: "flex" }}>
          <img src={Chef} alt="chef" />
          <div className={styles.summaryKey}>Total orders in queue</div>
        </div>
        <div className={styles.summaryValue}>
          {orderList && orderList.length > 0
            ? orderList.filter(
              (f) =>
                f.status === "Payment Pending" ||
                f.status === "Payment Completed"
            ).length
            : 0}
        </div>
      </div>

      {getItemsSummary()}
    </div>
  );
  const Tabs = (
    <div className={styles.tabsContainer}>
      <div
        onClick={() => setActiveTab(1)}
        className={activeTab === 1 ? styles.tabActive : styles.tabInactive}
      >
        Pending
      </div>
      <div
        onClick={() => setActiveTab(2)}
        className={activeTab === 2 ? styles.tabActive : styles.tabInactive}
      >
        Completed
      </div>
    </div>
  );

  const getDiningtype = (isTakeAway) => {
    if (isTakeAway) {
      return TakeAway;
    } else {
      return Havingit;
    }
  };

  const getPaymentType = (type) => {
    if (type === "Cash") {
      return (
        <div className={styles.cashTypeContainer}>
          <img src={Cash} alt="cash" />
          <div className={styles.cashText}>Cash</div>
        </div>
      );
    } else {
      return (
        <div className={styles.onlineTypeContainer}>
          <img src={Online} alt="online" />
          <div className={styles.onlineText}>Paid</div>
        </div>
      );
    }
  };
  const leadingActions = (id, row) => (
    <LeadingActions>
      <SwipeAction destructive={true} onClick={() => markComplete(id, row)}>
        <div className={styles.swipeContainer}>Done</div>
      </SwipeAction>
    </LeadingActions>
  );
  const trailingActions = (id, row) => (
    <TrailingActions>
      <SwipeAction destructive={true} onClick={() => cancelOrder(id, row)}>
        <div className={styles.cancelContainer}>Cancel</div>
      </SwipeAction>
    </TrailingActions>
  );

  const markComplete = (id, row) => {
    updateOrderStatus(id, row);
    // getKiosk(row)
  };


  const areUSureDelete = async (choose) => {
    setIsLoading(true)
    if (choose) {
      console.log('yes')
      const response = await deleteOne(ORDER, idProductRef.current);
      handleDialog("", false);
      if (response.data) {
        toast.success('Success');
        getSalesReports()
        setIsLoading(false)
        window.location.reload();
      }
    } else {
      console.log('no')
      window.location.reload();
      handleDialog("", false);
      setIsLoading(false)
    }
  };

  const handleDialog = (message, isLoading, nameProduct) => {
    setDialog({
      message,
      isLoading,
      nameProduct
    });
  };

  const cancelOrder = async (id, row) => {
    try {
      console.log('this')
      idProductRef.current = id;
      console.log(idProductRef.current)
      handleDialog("Are you sure you want to delete?", true, row.token);
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message);
    }
  };

  const updateOrderStatus = async (id, row) => {
    try {
      setIsLoading(true)
      const response = await update(ORDER, id, {
        status: "Completed",
        kid: row.kid,
        token: row.token,
      });
      if (response.data) {
        toast.success("Success");
        if (socket.connected) socket.emit("orderPlaced");
        getSalesReports();
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false)
      // toast.error(error.response.data.message);
    }
  };

  const orders = (
    <>
      {(activeTab === 1
        ? orderList.filter(
          (f) =>
            f.status === "Payment Pending" || f.status === "Payment Completed"
        )
        : orderList.filter((f) => f.status === "Completed")
      ).length === 0 ? (
        <div className={styles.noData}>No Data Found !</div>
      ) : (
        <SwipeableList>
          <div style={{ margin: "22px 23px" }}>
            {(activeTab === 1
              ? orderList
                .filter(
                  (f) =>
                    f.status === "Payment Pending" ||
                    f.status === "Payment Completed"
                )
                .sort((a, b) => a.token - b.token)
              : orderList
                .filter((f) => f.status === "Completed")
                .sort((a, b) => b.token - a.token)
            ).map((e, i) => (
              <SwipeableListItem
                key={e._id}
                threshold={0.25}
                blockSwipe={
                  e.status === "Payment Pending" ||
                    e.status === "Payment Completed"
                    ? false
                    : true
                }
                // keyExtractor={(item, index) => console.log(item,index)}
                leadingActions={leadingActions(e._id, e)}
                trailingActions={trailingActions(e._id, e)}
              >
                <div className={styles.cardContainer}>
                  <div
                    className={
                      e.transactiontype === "Cash"
                        ? styles.cardCash
                        : styles.cardOnline
                    }
                  >
                    <div className={styles.paymentType}>
                      {getPaymentType(e.transactiontype)}
                    </div>
                    <div
                      className={
                        e.transactiontype === "Cash"
                          ? styles.tokenNumberCash
                          : styles.tokenNumberOnline
                      }
                    >
                      {e.token}
                    </div>
                  </div>
                  <div className={styles.cardDetails}>
                    <div className={styles.itemsTotal}>
                      &#8377;{e.totalamount}
                    </div>
                    <div className={styles.items}>
                      {e.items.map((i) => (
                        <div
                          key={i._id}
                          style={{ display: "flex", marginTop: "12px" }}
                        >
                          <img
                            style={{ width: "17px", height: "17px" }}
                            src={skus.filter((r) => r._id === i.skuid)[0]?.icon}
                            alt="chef"
                          />
                          <div className={styles.itemName}>
                            {skus.filter((r) => r._id === i.skuid)[0]?.skuName}{" "}
                            x {i.quantity}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className={
                      activeTab === 2
                        ? styles.cardTypeTakeAway
                        : styles.cardTypeDining
                    }
                  >
                    <img
                      src={getDiningtype(e.takeaway)}
                      alt={getDiningtype(e.takeaway)}
                    />
                    {activeTab === 2 ? (
                      <img style={{ marginBottom: 15 }} src={Done} alt="done" />
                    ) : null}
                  </div>
                </div>
              </SwipeableListItem>
            ))}
          </div>
        </SwipeableList>
      )}
      {dialog.isLoading && (
        <Dialog
          //Update
          nameProduct={dialog.nameProduct}
          onDialog={areUSureDelete}
          message={dialog.message}
        />
      )}
    </>
  );

  return (
    <>
      {loading && <LoadingSpinner />} <div>
        {Header}
        {Summary}
        {Tabs}
        {orders}
      </div>
    </>
  );
};
export default Home;
